// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const cn = {
  home: {
    home1: "开发者",
    home2: "如何使用",
    home3: "多链应用的Web3.0数字身份网关",
    home4: "多链钱包｜DApp浏览器｜跨链兑换",
    home5: "移动端下载",
    home6: "桌面插件下载",
    home7: "秒级跨链",
    home8: "一键资产跨链互转，资产跨链闪兑",
    home9: "多协议兼容",
    home10: "兼容MetaMask、兼容WalletConnect 协议，各种 DApp应用快速连接",
    home11: "多链支持",
    home12: "支持Ethereum/BNB Chain/Arbitrum/OKX Chain/ Polygon/TRON/KCC/NULS/Nerve多链生态DApp",
    home13: "用户生态",
    home14: "当前已有用户体量：300k+",
    home15: "文档",
    home16: "提交DApp",
    home17: "提交Token",
    home18: "下载旧版Nabox",
    home19: "Chrome商店下载",
    home20: "本地下载",
    home21: "新版（敬请期待）",
    home22: "旧版Android",
    home23: "旧版IOS",
    home24: "IOS下载",
    home25: "Android本地下载",
    home26: "论坛",
    home27: "Android Google Play 下载",
    home28: "Google Play",
    home29: "Android",
    home30: "合作",
    home31: "Apple Store",
    home32: "Android Apk"
  },
  roadMap: {
    roadMap1: "路线图",
    roadMap2: "已完成",
    roadMap3: "Nabox App/Extension支持更多语言包",
    roadMap4: "Nabox App/Extension集成支持Taproot和Bitcoin生态应用",
    roadMap5: "Nabox App/Extension集成支持Solana网络和生态应用",
    roadMap6: "Nabox App/Extension集成支持Ton网络和生态应用",
    roadMap7: "集成其他多个主流区块链",
    roadMap8: "Nabox App改进DApp浏览器多开，提升用户体验",
    roadMap9: "Nabox App/Extension优化对接文档",
    roadMap10: "SwapBox新版本推出",
    roadMap11: "优化SwapBox SDK和文档",
    roadMap12: "Nabox ID任务功能迭代,推出项目Space和积分定制",
    roadMap13: "Nabox ID支持DApp交互任务",
    roadMap14: "Nabox ID身份信用分体系",
    roadMap15: "PayBox支付系统产品推出",

    roadMap16: "改进市场/DeFi等数据展示，提供热门项目快速兑换",
    roadMap17: "官网/品牌更新",
    roadMap18: "Nabox Extension 优化多链支持Ledger",
    roadMap19: "Nabox App/Extension 集成Move系公链生态Aptos/Sui等",
    roadMap20: "Nabox Extension Blinks相关的研究和集成",
    roadMap21: "Nabox App/Extension 优化交易记录/DApps等信息展示",
    roadMap22: "PayBox用户体验改进优化",
    roadMap23: "Nabox ID集成更多主流网络支持",
    roadMap24: "Nabox ID支持NFT mint功能",
    roadMap25: "SwapBox聚合通道新增更多网络",
  },
  team: {
    team1: "发起人",
    team2: "早期的加密投资者和企业家，区块链生态系统中的资深人士，参与了NULS项目早期投资，后来成为NULS的理事会成员。一直在新加坡地区帮助NULS团队建立当地的伙伴关系。",
  },
  partner: {
    partner1: "已支持网络",
    partner2: "Nabox支持了多条区块链网络，您可通过Nabox的跨链功能将资产快捷地转入不同网络，参与各个网络的应用"
  },
  investors: {
    investors1: "投资机构"
  },
  bottom: {
    bottom1: "关注我们",
    bottom2: "Twitter",
    bottom3: "Discord",
    bottom4: "Medium",
    bottom5: "文档",
    bottom6: "如何使用Nabox插件",
    bottom7: "DApp连接Nabox插件",
    bottom8: "合作",
    bottom9: "提交Token",
    bottom10: "提交DApp",
    bottom11: "品牌资源",
    bottom12: "生态"
  }
  // ...zhLocale
};

export default cn
