import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
  home: {
    home1: "Developer",
    home2: "How To Use",
    home3: "The Multi-Chain DID Gateway to Web3",
    home4: "Multi-Chain Wallet  | DApp Explorer | Cross-Chain Swap",
    home5: "Mobile",
    home6: "Chrome Extension",
    home7: "Second-layer Cross-Chain",
    home8: "One-click cross-chain for easy swaps across different chains",
    home9: "Compatible With Multiple Protocols",
    home10: "Works with MetaMask, WalletConnect & various DApps",
    home11: "Supports Multiple Chains",
    home12: "Supports digital asset management across Ethereum/BNB Chain /Arbitrum/OKX Chain/Polygon/Tron/KCC/NULS/NERVE etc",
    home13: "Userbase",
    home14: "Current active users: 300k+",
    home15: "Docs",
    home16: "Submit DApp",
    home17: "Submit Token",
    home18: "Download Legacy Nabox",
    home19: "Chrome Store Download",
    home20: "Local Download",
    home21: "New Version (Stay tuned)",
    home22: "Legacy Android",
    home23: "Legacy iOS",
    home24: "Download iOS",
    home25: "Download Android (Local)",
    home26: "Forum",
    home27: "Download Android",
    home28: "Google Play",
    home29: "Android",
    home30: "Cooperation",
    home31: "Apple Store",
    home32: "Android Apk"
  },
  roadMap: {
    roadMap1: "Roadmap",
    roadMap2: "Completed",
    roadMap3: "Nabox App/Extension supports more languages.",
    roadMap4: "Nabox App/Extension integrates with Taproot and Bitcoin ecosystem applications.",
    roadMap5: "Nabox App/Extension integrates with Solana network and Solana ecosystem applications.",
    roadMap6: "Nabox App/Extension integrates with Ton Network and Ton ecosystem applications.",
    roadMap7: "Integration with more mainstream blockchains.",
    roadMap8: "Enhance the Nabox App by improving multi-browser access for DApps to optimize user experience.",
    roadMap9: "Nabox App/Extension optimizes interfacing documentation.",
    roadMap10: "Launch of the new version of SwapBox.",
    roadMap11: "Optimization of SwapBox SDK and documentation.",
    roadMap12: "Nabox ID task function iteration, launching the Space for projects and points customization.",
    roadMap13: "Nabox ID supports DApp interaction tasks.",
    roadMap14: "Nabox ID identity credit scoring system.",
    roadMap15: "Launch of PayBox payment system product.",

    roadMap16: "Improve Market/DeFi data display.",
    roadMap17: "Official website and brand update.",
    roadMap18: "Nabox Extension optimizes multi-chain support for Ledger.",
    roadMap19: "Nabox App/Extension integrates Move Chains ecosystem Aptos/Sui, etc.",
    roadMap20: "Nabox Extension Blinks research and integration.",
    roadMap21: "Nabox App/Extension optimizes Records/DApps.",
    roadMap22: "PayBox user experience improvement and optimization.",
    roadMap23: "Nabox ID integrates more chains support.",
    roadMap24: "Nabox ID supports NFT mint function",
    roadMap25: "SwapBox aggregation channel adds more chains",
  },
  team: {
    team1: "Project Lead",
    team2: "As an early crypto investor and entrepreneur, Ang Kang Wei is a veteran in the blockchain ecosystem. He is an early investor of NULS and has been actively building partnerships and collaborations in Singapore over the years, now holding an esteemed position as NULS Council Member.",
  },
  partner: {
    partner1: "Chains Supported",
    partner2: "Nabox supports multiple blockchain networks. You can quickly transfer assets to different networks through the cross-chain function of Nabox, and participate in the applications of each network."
  },
  investors: {
    investors1: "Investors"
  },
  bottom: {
    bottom1: "Follow Us",
    bottom2: "Twitter",
    bottom3: "Discord",
    bottom4: "Medium",
    bottom5: "Docs",
    bottom6: "How to use Nabox Extension",
    bottom7: "DApp connect Nabox Extension",
    bottom8: "Cooperation",
    bottom9: "Submit Token",
    bottom10: "Submit DApp",
    bottom11: "Brand Resources",
    bottom12: "Ecosystem"
  }
  // ...enLocale
};

export default en

